












import { Component } from 'vue-property-decorator'
import { FlowsService } from '../../includes/services/FlowService'
import Flow from 'piramis-base-components/src/components/Flow/Flow.vue'
import { FlowConfig } from 'piramis-base-components/src/components/Flow/logic/types/type'
import CashRegisterToken from '../../components/HelpMessages/CashRegisterToken.vue'
import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import FlowMainHint from "../../components/HelpMessages/flow/FlowMainHint.vue"
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { FlowData, Scheme } from 'piramis-base-components/src/components/Flow/logic/types/GeneralTypes'
import FileApi from 'piramis-base-components/src/components/File/logic/file-api'
import { FLowProject } from 'piramis-base-components/src/components/Flow/logic/FlowEnums'
import router from '@/router'
import { ChannelService } from '@/includes/services/ChannelService'
import BaseFlow from 'piramis-base-components/src/components/Flow/BaseScreenshotFlowSheme'
import { ProductState } from "piramis-base-components/src/components/Shop/Products/types";
import { CashRegistersState } from "piramis-base-components/src/components/Shop/CashRegisters/types";
import { TestBoardStateCode } from "@/includes/types/Board.types";
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

@Component({
  components: {
    Flow,
  }
})
export default class NewFlow extends InputSetups {
  flowData: FlowData | null = null

  config: FlowConfig | null = null

  get isScreenshot(): boolean {
    return this.$route.name !== 'Flow_Screenshot'
  }

  saveFlow(Scheme: Scheme): void {
    FlowsService.updateFlow('tg', {
      flow_key: this.$route.query.flow_key as string,
      board_key: this.$store.state.boardsState.activeBoard!.board,
      title: this.flowData!.data.title,
      scheme: Scheme
    })
      .then(() => {
        successNotification(this.$t('save_flow_success').toString())
      })
      .catch(error => {
        errorNotification(error)
      })
      .finally(() => {
        window.onbeforeunload = null
      })
  }

  getPreviewUrl(file: string): string {
    if (this.$route.name !== 'Flow_Screenshot') {
      const api = new FileApi('api.presscode.app/cabinet')

      return `${ api.getApiUrl() }/getfilepreview?file_descriptor=${ file }&target=${ this.$store.state.boardsState.activeBoard!.board }`
    }
    return ''
  }

  async mounted(): Promise<void> {
    let boardKey: null | { board_key: string } = null

    if (this.isScreenshot) {
      boardKey = { board_key: this.$store.state.boardsState.activeBoard!.board }

      if (!this.$store.state.shopState.products) {
        await this.$store.dispatch('getProducts', boardKey)
      }
      if (!this.$store.state.shopState.cashRegisters) {
        await this.$store.dispatch('getCashRegisters', boardKey)
      }
      if (!this.$store.state.flowsState.flows) {
        await this.$store.dispatch('requestFlows', boardKey)
      }
      if (!this.$store.state.formsState.forms) {
        await this.$store.dispatch('getForms')
      }
    }

    this.config = {
      startTestingFlow: () => FlowsService.runTestingFlow('tg', { board_key: boardKey!.board_key, flow_key: this.$route.query.flow_key as string  }),
      version: this.$store.state.boardsState.activeBoard?.features_version,
      readonly: this.$route.name === 'Flow_Screenshot',
      helpViews: {
        messageEditorButtonHelpView: EditorButtonsHelpView,
        cashRegisterTokenHelpMessage: CashRegisterToken,
        mainFlowHelpView: FlowMainHint,
      },
      previewUrl: this.getPreviewUrl,
      messageEditorButtons: this.flowButtons,
      project: FLowProject.PressCode,
      store: {
        state: {
          flows: this.$store.state.flowsState.flows,
          shop: {
            products: this.$store.state.shopState.products?.filter((p) => p.state === ProductState.Active),
            cashRegisters: this.$store.state.shopState.cashRegisters?.filter((c) => c.state === CashRegistersState.Active),
          },
          board: {
            ...this.$store.state.boardsState.activeBoard,
            botDomainError: this.$store.state.boardsState.activeBoard?.test_state === TestBoardStateCode.BotDomainError ? {
              state: false,
              modalComponent: '',
            } : null
          }
        },
        methods: {
          renameFlow: (args) => {
            return this.$store.dispatch('renameFlow', args)
          },
          createLabel: (label) => {
            return this.$store.dispatch('createLabel', label)
          },
          getUsersInfo: (users) => {
            return FlowsService.getUsersInfo('tg', { board_key: boardKey!.board_key, values: users })
          },
          checkChannels: (channels) => {
            return FlowsService.checkChannels('tg', { board_key: boardKey!.board_key, values: channels })
          },
          createdProduct: (product) => {
            return this.$store.dispatch('createProduct', product)
          },
          getChannelLimitedType: (id) => {
            return new Promise(resolve => {
              const route = router.resolve({ name: 'Channel_subscription', params: {
                [EntityTypes.BOT_ID]: router.currentRoute.params[ EntityTypes.BOT_ID ],
                [EntityTypes.CHANNEL_ID]: String(id)
              } })

              ChannelService.getChannelConfig('tg', { board_key: boardKey!.board_key, channel_id: id })
                .then((res) => {
                  resolve({
                    id: id,
                    title: res.title,
                    limited_subscription_type: res.config.limited_subscription_type,
                    href: route.href
                  })
                })
            })
          },
          createProperty: (prop) => {
            return this.$store.dispatch('createProperty', prop)
          },
          createCashRegister: (cashRegister) => {
            return this.$store.dispatch('createCashRegister', Object.assign(cashRegister, { type: 'Telegram', board_key: this.$store.state.boardsState.activeBoard!.board }))
          },
        },
        getters: {
          admins: this.$store.state.boardsState.activeBoard?.admins
            .slice()
            .filter(a => a.limits === null)
            .map(a => ({
              value: a.user_id,
              label: `${ a.name } ${ a.username ? '(@' + a.username + ')' : '' }`
            })),
          currencyList: [
            { label: "RUB", value: "RUB" },
            { label: "USD", value: "USD" },
            { label: "EUR", value: "EUR" },
          ],
          apiArgs: {
            targetToUpload() {
              return {
                target: 'target',
                target_id: boardKey!.board_key
              }
            },
            baseApiUrl: 'api.presscode.app/cabinet'
          },
          properties: this.isScreenshot ? () => this.$store.getters.properties : () => [],
          botTargets: this.isScreenshot ? this.$store.getters.botTargets : [],
          flowPlaceholders: this.isScreenshot ? this.$store.getters.flowPlaceholders : [],
          labels: () => this.isScreenshot ? this.$store.getters.labels : {},
        }
      },
    }

    if (this.$route.name !== 'Flow_Screenshot') {
      const flowRes = await FlowsService.getFlow('tg', Object.assign(boardKey,{ flow_key: this.$route.query.flow_key as string }))

      this.flowData = flowRes.flow
    } else {
      try {
        const flowRes = await FlowsService.getFlowThumbnailData('tg', { request: this.$route.params.request })

        this.flowData = flowRes.flow
      } catch (e) {
        this.flowData = BaseFlow
      }
    }
  }
}
