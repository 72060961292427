








































import Vue from 'vue'
import Component from 'vue-class-component'

import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'

@Component({
  components: {
    ZoomableImage
  }
})
export default class CashRegisterToken extends Vue {

}
